import React, { useState } from "react";
import { navigate } from "gatsby";
import { jwtDecode } from "jwt-decode";
import { setSessionStorage, removeSessionStorage } from "@utils/helper";

const defaultState = {
  token: null,
  modalOpen: false,
  setModalOpen: () => {},
  externalUrl: null,
  setExternalUrl: () => {},
  modalType: null,
  setModalType: () => {},
  logoutTimer: null,
};

const AppContext = React.createContext(defaultState);

const AppProvider = ({ children }) => {
  const [token, setToken] = useState(defaultState.token);
  const [modalOpen, setModalOpen] = useState(defaultState.modalOpen);
  const [externalUrl, setExternalUrl] = useState(defaultState.externalUrl);
  const [modalType, setModalType] = useState(defaultState.modalType);
  const [logoutTimer, setLogoutTimer] = useState(defaultState.logoutTimer);

  const startLogoutTimer = (token) => {
    if (token) {
      clearLogoutTimer();
      const decoded = jwtDecode(token);
      if (decoded && decoded.exp) {
        const expirationTime = decoded.exp * 1000;
        const timer = setTimeout(() => {
          console.log("Logout timer expired");
          // Perform logout actions here, like clearing token and redirecting to login page
          setToken(null);
          removeSessionStorage("spToken");
          navigate("/");
        }, expirationTime - new Date().getTime());
        setLogoutTimer(timer);
      }
    }
  };

  const clearLogoutTimer = () => {
    clearTimeout(logoutTimer);
    setLogoutTimer(null);
  };

  const resetToken = (token) => {
    setToken(token);
    startLogoutTimer(token);
    setSessionStorage("spToken", token);
  };

  const store = {
    token: token,
    setToken: setToken,
    modalOpen: modalOpen,
    setModalOpen: setModalOpen,
    externalUrl: externalUrl,
    setExternalUrl: setExternalUrl,
    modalType: modalType,
    setModalType: setModalType,
    logoutTimer: logoutTimer,
    setLogoutTimer: setLogoutTimer,
    startLogoutTimer: startLogoutTimer,
    clearLogoutTimer: clearLogoutTimer,
    resetToken: resetToken,
  };
  return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};

export default AppContext;

export { AppProvider };
