let distanceScrolled = -1;
let distanceThreshold = [
    { threshold: 10, value: '10%', sent: false },
    { threshold: 25, value: '25%', sent: false },
    { threshold: 50, value: '50%', sent: false },
    { threshold: 75, value: '75%', sent: false },
    { threshold: 100, value: '100%', sent: false },
];

const scrollDepth = () => {
    const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    let progress = Math.round(
        (scrollTop / (document.body.scrollHeight - window.innerHeight)) * 100
    );

    if (isNaN(progress)) progress = 0;

    if (progress > distanceScrolled) {
        distanceScrolled = progress;

        for (let i = 0; i < distanceThreshold.length; i++) {
            const status = distanceThreshold[i].sent;
            const threshold = distanceThreshold[i].threshold;
            const value = distanceThreshold[i].value;

            if (!status && progress >= threshold) {
                if ('dataLayer' in window) {
                    window.dataLayer.push({
                        event: 'gatsbyScroll',
                        gatsbyScrollDistance: value,
                    });
                    distanceThreshold[i].sent = true;
                }
            }
        }
    }
};

const debounce = (func, wait, immediate) => {
    let timeout = null;

    return function () {
        const context = this;
        const args = arguments;

        const later = () => {
            timeout = null;

            if (!immediate) {
                func.apply(context, args);
            }
        };

        const callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) {
            func.apply(context, args);
        }
    };
};


const scrollListener = debounce(scrollDepth, 18);

const GTM = {
    init: () => {
        const interval = setInterval(() => {
            if ('dataLayer' in window && 'google_tag_manager' in window) {
                window.dataLayer.push({ event: 'gatsby-route-change' });
                clearInterval(interval);
            }
        }, 100);
        scrollDepth();
        document.addEventListener('scroll', scrollListener);
    },
    // form tags START
    formInputChange: () => {
        if ('dataLayer' in window) {
            window.dataLayer.push({
                event: 'inputChange',
                fieldValue: 'passcode',
                formName: 'Login Form',
            });
        }
    },
    formSubmission: () => {
        if ('dataLayer' in window) {
            window.dataLayer.push({ event: 'formCompletion', formName: 'Login Form' });
        }
    },
    formFieldCompletionFormSubmitted: (fieldName, fieldValue) => {
        if ('dataLayer' in window) {
            window.dataLayer.push({
                event: 'formFieldCompletionFormSubmitted',
                formName: 'Login Form',
                fieldName,
                fieldValue,
            });
        }
    },
    formError: (error) => {
        if ('dataLayer' in window) {
            window.dataLayer.push({
                event: 'formError',
                fieldValue: 'passcode',
                formName: 'Login Form',
                formErrorMessage: error,
            });
        }
    },
    // form tags END
    otherError: (errorType, errorCode, errorMsg) => {
        if ('dataLayer' in window) {
            window.dataLayer.push({
                event: 'otherError',
                otherErrorTypeCode: `${errorType}/${errorCode}`,
                otherErrorMessage: errorMsg,
            });
        }
    },
    fileDownload: (filename) => {
        if ('dataLayer' in window) {
            window.dataLayer.push({
                event: 'fileDownload',
                filename: filename,
            });
        }
    },
    toggleAccordion: (isExpanded, accHeading) => {
        if ('dataLayer' in window) {
            window.dataLayer.push({
                event: 'toggleAccordion',
                accordionState: isExpanded ? 'Collapse' : 'Expand',
                accordionLabel: accHeading,
            });
        }
    },
    modal: (action, label) => {
        if ('dataLayer' in window) {
            window.dataLayer.push({
                event: 'modalChange',
                modalAction: action,
                exitUrl: label,
            });
        }
    },
    reset: () => {
        distanceScrolled = -1;
        distanceThreshold.forEach((threshold) => (threshold.sent = false));
    },
};

export default GTM;
