import { jwtDecode } from "jwt-decode";
import GTM from "@utils/GTM.js";

export const isEDMode =
  typeof window !== "undefined"
    ? window.location.href.indexOf("ed=1") > 0
    : false;
export const mobileMedia = "(min-width: 0px) and (max-width: 1200px)";
export const tabletMedia = "(min-width: 768px) and (max-width: 1200px)";

export const isMobile = (media = false) => {
  if (typeof window !== "undefined") {
    return window.matchMedia(media || mobileMedia).matches;
  }

  return true;
};

export const isTablet = () => {
  if (typeof window !== "undefined") {
    return window.matchMedia(tabletMedia).matches;
  }

  return true;
};

export const getWinSize = () => {
  if (typeof window !== "undefined") {
    const w = window;
    const d = document;
    const e = d.documentElement;
    const g = d.getElementsByTagName("body")[0];
    const x = w.innerWidth || e.clientWidth || g.clientWidth;
    const y = w.innerHeight || e.clientHeight || g.clientHeight;

    return { width: x, height: y };
  }

  return { width: 0, height: 0 };
};

export const debounce = (func, wait, immediate) => {
  let timeout = null;

  return function () {
    const context = this;
    const args = arguments;

    const later = () => {
      timeout = null;

      if (!immediate) {
        func.apply(context, args);
      }
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) {
      func.apply(context, args);
    }
  };
};

export const focusLoop = (e, selector) => {
  const tabPressed = e.key === "Tab" || e.keyCode === 9;

  if (!tabPressed) return;

  const elems = document.querySelectorAll(selector);

  if (elems?.length) {
    const firstElem = elems[0];
    const lastElem = elems[elems.length - 1];

    if (!Array.from(elems).includes(document.activeElement)) {
      firstElem.focus();
    }

    if (e.shiftKey) {
      if (document.activeElement === firstElem) {
        lastElem.focus();
        e.preventDefault();
      }
    } else {
      if (document.activeElement === lastElem) {
        firstElem.focus();
        e.preventDefault();
      }
    }
  }
};

export const getCookieByName = (cookieName) => {
  const cookieValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith(cookieName + "="));
  if (cookieValue === undefined) {
    return null;
  }
  return cookieValue.split("=")[1];
};

export const checkSettingsBtns = () => {
  const allowAllBtn = document.body.querySelector(
    "#accept-recommended-btn-handler",
  );
  const targetChecked = document.querySelector("#ot-group-id-C0002").checked;
  const performanceChecked =
    document.querySelector("#ot-group-id-C0004").checked;

  if (targetChecked && performanceChecked) {
    allowAllBtn.style.display = "none";
  } else {
    allowAllBtn.style.display = "inline-block";
  }
};

export const getUrlParameter = (param) => {
  param = param.replace(/[\\[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + param + "=([^&#]*)");
  const results = regex.exec(window.location.search);

  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

export const scrollToSection = (selector) => {
  if (typeof document !== "undefined") {
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  }
};

export const openCookieModal = () => {
  if (typeof window !== "undefined" && "OneTrust" in window) {
    window.OneTrust.ToggleInfoDisplay();
  }
};

export const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
};

export const fetchAPIwToken = () => {
  const getHeaders = (authToken, header) => {
    const headers = header ? header : { "Content-Type": "application/json" };
    if (authToken) {
      headers["x-api-key"] = authToken;
    }
    return headers;
  };

  const request = {
    get: async (url, authToken, data, header) => {
      const headers = getHeaders(authToken, header);
      const queryParams = data ? new URLSearchParams(data).toString() : "";
      const fetchUrl = queryParams ? `${url}?${queryParams}` : url;

      try {
        const response = await fetch(fetchUrl, {
          headers,
        });

        const json_resp = await response.json();

        return {
          success: response.ok,
          request: { url, headers, method: "get", payload: data },
          response: {
            status: response.status,
            body: json_resp,
          },
        };
      } catch (error) {
        const err_msg = error.stack;
        GTM.otherError(
          `failed_fetching_from_server`,
          error.name,
          `GET - ${error.message}`
        );
        return {
          success: false,
          request: { url, headers, method: "get", payload: data },
          err_msg,
        };
      }
    },
    post: async (url, authToken, data, header) => {
      const headers = getHeaders(authToken, header);

      try {
        const response = await fetch(url, {
          method: "POST",
          headers,
          body: JSON.stringify(data),
        });

        const json_resp = await response.json();
        return {
          success: response.ok,
          request: { url, headers, method: "post", payload: data },
          response: {
            status: response.status,
            body: json_resp,
          },
        };
      } catch (error) {
        const err_msg = error.stack;
        GTM.otherError(
          `failed_fetching_from_server`,
          error.name,
          `POST - ${error.message}`
        );
        return {
          success: false,
          request: { url, headers, method: "post", payload: data },
          err_msg,
        };
      }
    },
  };

  return request;
};

export const setSessionStorage = (key, value) => {
  if (typeof window !== "undefined") {
    removeSessionStorage(key);
    sessionStorage.setItem(key, value);
  }
};
export const getSessionStorage = (key) => {
  if (typeof window !== "undefined") {
    return sessionStorage.getItem(key);
  }
  return null;
};
export const removeSessionStorage = (key) => {
  if (typeof window !== "undefined") {
    sessionStorage.removeItem(key);
  }
};
export const isTokenExpired = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken && decodedToken.exp < Date.now() / 1000;
  } catch (error) {
    return true;
  }
};

export const handleRefreshToken = async (token) => {
  try {
    const decodedToken = jwtDecode(token); // Decoding the JWT token
    const expirationTimeInSeconds =
      decodedToken.exp - Math.floor(Date.now() / 1000); // Get remaining seconds until expiration

    // check if token will be expired in 5 minutes (300 seconds)
    if (expirationTimeInSeconds <= 300) {
      // Token will expire in 5 minutes or less
      console.log("Token will expire in 5 minutes or less");

      try {
        const response = await fetchAPIwToken().post(
          `${process.env.GATSBY_API_BASE_URL}/api/v1/auth/refresh`,
          token,
        );

        if (response && response?.response) {
          if (response.success && response.response.body.data.token) {
            return response.response.body.data.token;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  } catch (error) {
    console.error(error);
    GTM.otherError(
      `failed_refresh_token`,
      error.name,
      error.message
    );
  }
  return null;
};

export const downloadFile = async (type, apiUrl, authToken) => {
  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        "x-api-key": authToken,
      },
    });
    const decoded = jwtDecode(authToken);
    const timestamp = new Date().getTime();

    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      const filename = type === "product_list" ? "product_list_2024.csv" : `${type}_${decoded.sub}_${timestamp}.csv`;
      a.download = filename;
      GTM.fileDownload(filename);
      a.click();
      window.URL.revokeObjectURL(url);
    }
  } catch (error) {
    console.error(error);
    GTM.otherError(
      `failed_download_csv_${type}`,
      error.name,
      `GET - ${error.message}`
    );
  }
};
