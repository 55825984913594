import React from "react";
import { AppProvider } from "./src/context";
import GTM from "@utils/GTM.js";

// Supports weights 100-900
import "@fontsource-variable/work-sans";
import "@styles/global.css";
import "@styles/zoom.css";
import "@styles/cookie.css";
import "@styles/screenshots.css";


export const onRouteUpdate = ({ location }) => {
  GTM.reset();
};

export const wrapRootElement = ({ element }) => (
  <AppProvider>{element}</AppProvider>
);
